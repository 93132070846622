import React, { useState } from "react";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import Resource from "components/resource/Resource";
import Sorting from "./components/Sorting";

import tableData from "./variables/tableDataRekodHasil";
import { useRekodHasils } from "hooks/admin/rekod-hasil/useRekodHasils";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import FullScreenLoader from "components/loader/FullScreenLoader";

const columnHelper = createColumnHelper<any>();

export default function RekodHasil() {
  const { isLoading, error, rekodHasils } = useRekodHasils();

  const columns = [
    columnHelper.accessor("aliran_id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("usahawan_integrator", {
      header: () => <Text variant="tableHeading">Usahawan/ Integrator</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kp_ssm", {
      header: () => <Text variant="tableHeading">No. KP/ SSM</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("jenis_bantuan", {
      header: () => <Text variant="tableHeading">Jenis Bantuan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kategori_projek", {
      header: () => <Text variant="tableHeading">Kategori Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("subkategori_projek", {
      header: () => <Text variant="tableHeading">Sub Kategori</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_projek", {
      header: () => <Text variant="tableHeading">Nama Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_hasil", {
      header: () => <Text variant="tableHeading">Nama Hasil</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_tapak", {
      header: () => <Text variant="tableHeading">Tapak</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kuantiti_hasil", {
      header: () => <Text variant="tableHeading">Kuantiti Hasil</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("unit", {
      header: () => <Text variant="tableHeading">Unit Ukuran</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("hasil_rm", {
      header: () => <Text variant="tableHeading">Hasil (RM)</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={rekodHasils}
        title={"Senarai Rekod Hasil"}
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
