import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getNegeriOptions } from "services/setting/general/negeriService";
import { PAGE_SIZE } from "utils/constants";

export function useNegeriSettings(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("itemsPerPage")
    ? 50
    : Number(searchParams.get("itemsPerPage"));

  const {
    isLoading,
    data: negeris,
    error,
  } = useQuery({
    queryKey: ["negeris", currentPage, currentLimit, params],
    queryFn: () =>
      params?.paginate === false
        ? getNegeriOptions({ ...params })
        : getNegeriOptions({
            ...params,
            page: currentPage,
            itemsPerPage: currentLimit,
          }),
  });

  // OUTPUT
  return {
    negeris,
    isLoading,
    error,
  };
}
