import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Flex, SimpleGrid, Button, FormControl } from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import TextField from "components/fields/TextField";
import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { useNegeriSettings } from "hooks/setting/general/negeri/useNegeris";
import { usePTSettings } from "hooks/setting/general/pt/usePTs";
import { useDaerahSettings } from "hooks/setting/general/daerah/useDaerahs";

import { useBuletinActive } from "hooks/admin/buletin/useBuletinActive";
import { useCreateNotification } from "hooks/setting/notifikasi/useCreateNotification";
import { useEditNotification } from "hooks/setting/notifikasi/useEditNotification";
import CreatableSelectField from "components/fields/CreatableSelectField";
import useOptions from "hooks/setting/general/useOptions";

interface FormNotificationProps {
  action: "create" | "edit" | "show";
  initialData?: any;
}

const FormNotification: React.FC<FormNotificationProps> = ({
  action,
  initialData,
}) => {
  const [jenisNotifikasi, setJenisNotifikasi] = useState(
    initialData?.jenis_notifikasi ?? "1"
  );

  const [ptOptions, setPtOptions] = useState<any>([]);
  const [daerahOptions, setDaerahOptions] = useState<any>([]);

  const { negeris } = useNegeriSettings({ paginate: false });
  const { pts } = usePTSettings({ paginate: false });
  const { daerahs } = useDaerahSettings({ paginate: false });

  const { buletinActive, isLoading: isLoadingBuletinActive } =
    useBuletinActive();
  const history = useHistory();

  const { tahapKebenaranOps, negeriOps } = useOptions();

  const { createNotification, isCreating } = useCreateNotification();
  const { editNotification, isEditing } = useEditNotification();

  const schema = yup
    .object({
      buletin_id: yup.string().notRequired(),
      daerah_id: yup.lazy((value) =>
        Array.isArray(value)
          ? yup.array().of(yup.string()).notRequired()
          : yup.string().notRequired()
      ),
      jenis_notifikasi: yup.string().notRequired(),
      keterangan_notifikasi: yup.string().notRequired(),
      negeri_id: yup.lazy((value) =>
        Array.isArray(value)
          ? yup.array().of(yup.string()).notRequired()
          : yup.string().notRequired()
      ),
      pusat_tanggungjawap_id: yup.lazy((value) =>
        Array.isArray(value)
          ? yup.array().of(yup.string()).notRequired()
          : yup.string().notRequired()
      ),
      tajuk_notifikasi: yup.string().notRequired(),
    })
    .required();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      daerah_id: initialData?.daerahs?.map((rN: { id: any }) => rN.id) as any[],
      negeri_id: initialData?.negeris?.map((rN: { id: any }) => rN.id) as any[],
      pusat_tanggungjawap_id: initialData?.pusat_tanggungjawaps?.map(
        (rN: { id: any }) => rN.id
      ) as any[],
    },
  });

  const watchNegeri = watch("negeri_id");
  const watchPt = watch("pusat_tanggungjawap_id");

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createNotification({
        newNotification: {
          ...data,
          jenis_notifikasi: jenisNotifikasi,
        },
        id: null,
      });
    } else if (action === "edit") {
      editNotification({
        newNotification: {
          ...data,
          jenis_notifikasi: jenisNotifikasi,
        },
        id: initialData?.id,
      });
    }
  });

  const tajukNotifikasiOptions = useMemo(() => {
    if (!buletinActive?.data) return [];

    return buletinActive.data.map((item: any) => ({
      value: item.id,
      label: item.tajuk,
    }));
  }, [buletinActive]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "negeri_id" && pts?.data.items) {
        setValue("pusat_tanggungjawap_id", null);

        console.log(pts?.data.items);

        const pt = pts?.data.items
          .filter((pt: any) => {
            return value.negeri_id
              ?.map((i: any) =>
                typeof i === "object" ? parseInt(i.id) : parseInt(i)
              )
              .includes(pt.negeri_id);
          })
          .map((pt: any) => {
            return {
              label: pt.keterangan,
              value: pt.id,
            };
          });

        setPtOptions(pt);
      }

      if (name === "pusat_tanggungjawap_id" && daerahs?.data.items) {
        setValue("daerah_id", null);

        console.log(daerahs?.data.items);
        console.log(value?.pusat_tanggungjawap_id);

        const daerah = daerahs?.data.items
          .filter((daerah: any) => {
            return value.pusat_tanggungjawap_id
              ?.map((i: any) =>
                typeof i === "object" ? parseInt(i.id) : parseInt(i)
              )
              .includes(daerah.pusat_tanggungjawap_id);
          })
          .map((daerah: any) => {
            return {
              label: daerah.daerah,
              value: daerah.id,
            };
          });

        console.log(daerah);

        setDaerahOptions(daerah);
      }
    });

    return () => subscription.unsubscribe();
  }, [negeris, pts, daerahs, watch, setValue]);

  useEffect(() => {
    if (initialData) {
      if (pts) {
        const pt = pts?.data.items
          .filter((pt: any) =>
            initialData.negeris
              ?.map((rN: { id: any }) => rN.id)
              .includes(pt.negeri_id)
          )
          .map((pt: any) => {
            return {
              label: pt.keterangan,
              value: pt.id,
            };
          });

        setPtOptions(pt);
      }

      if (daerahs) {
        const daerah = daerahs?.data.items
          .filter((daerah: any) =>
            initialData?.pusat_tanggungjawaps
              ?.map((rD: { id: any }) => rD.id)
              .includes(daerah.pt_id)
          )
          .map((daerah: any) => {
            return {
              label: daerah.daerah,
              value: daerah.id,
            };
          });

        setDaerahOptions(daerah);
      }
    }
  }, [initialData, pts, daerahs]);

  useEffect(() => {
    if (jenisNotifikasi == "1") {
      reset({
        buletin_id: null,
      });
    }
  }, [jenisNotifikasi]);

  if (isLoadingBuletinActive) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex
          px="25px"
          mt="1rem"
          mb="1.5rem"
          height={"500px"}
          flexDirection={"column"}
        >
          <form
            style={{ height: "100%" }}
            onSubmit={onSubmit}
            autoComplete="off"
          >
            <Section height={"100%"} title="Maklumat Notifikasi">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <SelectField
                  label={"Jenis Notifikasi"}
                  options={[
                    {
                      value: "1",
                      label: "Umum",
                    },
                    {
                      value: "2",
                      label: "Buletin",
                    },
                  ]}
                  value={jenisNotifikasi}
                  onChange={(event: any) =>
                    setJenisNotifikasi(event?.target.value)
                  }
                />

                {jenisNotifikasi == "1" ? (
                  <TextField
                    id="tajuk_notifikasi"
                    label="Tajuk Notifikasi"
                    placeholder="Tajuk Notifikasi"
                    // isInvalid={!!errors.tajuk_notifikasi}
                    // errorMessage={errors.tajuk_notifikasi?.message}
                    readOnly={action === "show"}
                    {...register("tajuk_notifikasi")}
                  />
                ) : (
                  <SelectField
                    label={"Tajuk Notifikasi"}
                    options={tajukNotifikasiOptions}
                    // isInvalid={!!errors.buletin_id}
                    // errorMessage={errors.buletin_id?.message}
                    readOnly={action === "show"}
                    {...register("buletin_id")}
                  />
                )}

                <TextField
                  id="keterangan_notifikasi"
                  label="Keterangan Notifikasi"
                  // isInvalid={!!errors.keterangan_notifikasi}
                  // errorMessage={errors.keterangan_notifikasi?.message}
                  readOnly={action === "show"}
                  {...register("keterangan_notifikasi")}
                />

                <CreatableSelectField
                  label={"Negeri"}
                  defaultOptions={negeriOps}
                  defaultValue={initialData?.negeris}
                  isMulti
                  onChange={(value) => {
                    setValue("negeri_id", value);
                  }}
                  isInvalid={!!errors.negeri_id}
                  readOnly={action === "show"}
                />

                <CreatableSelectField
                  label={"Pusat Tanggungjawab"}
                  defaultOptions={ptOptions}
                  defaultValue={initialData?.pusat_tanggungjawaps}
                  isMulti
                  onChange={(value) => {
                    setValue("pusat_tanggungjawap_id", value);
                  }}
                  isInvalid={!!errors.pusat_tanggungjawap_id}
                  readOnly={action === "show"}
                />

                <CreatableSelectField
                  label={"Daerah"}
                  defaultOptions={daerahOptions}
                  defaultValue={initialData?.daerahs}
                  isMulti
                  onChange={(value) => {
                    setValue("daerah_id", value);
                  }}
                  isInvalid={!!errors.daerah_id}
                  readOnly={action === "show"}
                />
              </SimpleGrid>

              <Flex
                gap="20px"
                justifyContent={"flex-end"}
                mb={"20px"}
                mt="auto"
              >
                <Button
                  variant="outline"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  colorScheme="gray"
                  type={"button"}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Kembali
                </Button>

                {action !== "show" && (
                  <Button
                    variant="brand"
                    fontSize={"sm"}
                    fontWeight={"500"}
                    minW={"183px"}
                    type="submit"
                  >
                    {action === "edit"
                      ? "Kemaskini Notifikasi"
                      : "Daftar Notifikasi Baru"}
                  </Button>
                )}
              </Flex>
            </Section>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormNotification;
