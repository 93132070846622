import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getSyarikatPerniagaanList } from "services/admin/kewangan/kewanganService";

export function useSyarikatPerniagaanList(params?: any) {


  const {
    isLoading,
    data: syarikatPerniagaanList,
    error,
  } = useQuery({
    queryKey: ["syarikatPerniagaanList", ],
    queryFn: () =>
      getSyarikatPerniagaanList({
        ...params,
        page: 1,
        itemsPerPage: -1,
      }),
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
  });

  return { isLoading, error, syarikatPerniagaanList };
}