import axios from "utils/axiosInstance";

export async function getDokumenPerniagaan(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/dokumen-perniagaan",
    params,
  });
  return data;
}

export async function getTunaiMasuk(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/aliran-tunai",
    params,
  });
  return data;
}

export async function getDokumenURL(type: string | null, params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: `/kewangan/get-dokumen-url/${type}`,
    params,
  });
  return data;
}

export async function getUsahawanIntegratorList(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: `/kewangan/usahawan-integrator-list`,
    params,
  });
  return data;
}

export async function getSyarikatPerniagaanList(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: `/kewangan/syarikat-perniagaan-list`,
    params,
  });
  return data;
}
