import axios from "axios";
import MockAdapter from 'axios-mock-adapter';
import Cookies from "js-cookie";
import { API_URL, ISPROD, USE_MOCK } from "config";

interface AxiosResponse {
  status?: number;
  error?: string;
  data?: any;
}

interface AxiosProps {
  url: string;
  method: string;
  token?: string;
  data?: any;
  params?: any;
  customContentType?: string;
  ignoreRedirect401?: boolean;
  public?: boolean;
}

// Create and configure the axios instance
const axiosInstance = createAxiosInstance();

// Optionally set up a mock adapter
const mock = setupMock(axiosInstance);

// Set up interceptors
setupInterceptors(axiosInstance);

async function call(config: AxiosProps): Promise<AxiosResponse> {
  const apiUrl = `${API_URL}${config.url}`;
  const headers = buildHeaders(config, apiUrl);

  try {
    const response = await axiosInstance({
      method: config.method,
      url: apiUrl,
      data: config.data,
      params: config.params,
      headers: headers,
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    return handleError(error, config);
  }
}

function createAxiosInstance() {
  return axios.create({
    baseURL: API_URL
  });
}

function setupMock(axiosInstance: any) {
  if (USE_MOCK) {
    return new MockAdapter(axiosInstance, { delayResponse: 1000, onNoMatch: "throwException" });
  }
  return null;
}

function setupInterceptors(axiosInstance: any) {
  axiosInstance.interceptors.response.use((response: any) => {
    if (!ISPROD) {
      console.log('Response:', JSON.stringify(response, null, 2));
    }
    return response;
  }, (error: any) => {
    if (!ISPROD) {
      console.error('Response Error:', error);
    }
    return Promise.reject(error);
  });
}

function buildHeaders(config: AxiosProps, apiUrl: string): Record<string, string> {
  const headers: Record<string, string> = {};
  const token = Cookies.get('userToken');

  if (token && !config.public) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  if (config.customContentType) {
    headers["Content-Type"] = config.customContentType;
  }

  headers["X-ACCESS-FROM"] = `ADMIN`;

  return headers;
}

function handleError(error: any, config: AxiosProps): AxiosResponse {
  const status = error.response?.status || error.status || 0;
  const message = error.response?.data?.message || error.message || `error.${status}`;

  throw new Error(message);
}

export default { axiosInstance, mock, call };
