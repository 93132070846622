import React from "react";
import { Flex, FormControl, FormLabel, Select, FormErrorMessage } from "@chakra-ui/react";


interface SelectFieldProps {
  label?: string;
  variant?: string;
  labelColor?: string;
  labelBottom?: any;
  value?: number | string;
  options: { value: number | string; label: string }[];
  isDisabled?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  [key: string]: any;
}

const SelectField = React.forwardRef<any, SelectFieldProps>(
  (
    {
      label,
      variant = "auth",
      labelColor = "navy.700",
      labelBottom = "8px",
      options = [],
      value,
      isDisabled = false,
      isInvalid = false,
      errorMessage = "",
      readOnly = false,
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl isInvalid={isInvalid}>
        {label && (
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            color={labelColor}
            fontWeight="500"
            mb={labelBottom}
          >
            {label}
          </FormLabel>
        )}

        <Select
          variant={variant}
          fontSize={"sm"}
          fontWeight={"400"}
          defaultValue={value || ""}
          isDisabled={isDisabled || readOnly}
          isReadOnly={readOnly}
          ref={ref}
          {...rest}
        >
          <option value="" disabled>Pilihan..</option>
          {options.map((option: { value: string | number | readonly string[]; label: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal; }, index: React.Key) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>

        {isInvalid && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </FormControl>
    );
  }
);

export default SelectField;
