import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";

interface CreatableSelectFieldProps {
  label: string;
  defaultValue?: any;
  defaultOptions: { value: number | string; label: string }[];
  isMulti?: boolean;
  onChange: (newValue: any) => void;
  onCreateOption?: (inputValue: string) => void;
  isLoading?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
}

interface Option {
  readonly label: string;
  readonly value: string | number;
}

export default function CreatableSelectField(props: CreatableSelectFieldProps) {
  const {
    label,
    defaultValue,
    defaultOptions = [],
    isMulti = false,
    onChange,
    onCreateOption,
    isLoading = false,
    required = false,
    isInvalid = false,
    errorMessage = "",
    readOnly = false,
  } = props;

  const [value, setValue] = useState<Option[]>([]);

  const handleCreate = (inputValue: string) => {
    onCreateOption?.(inputValue);
  };

  const handleOnChange = (newValue: any, actionMeta: any) => {
    setValue(newValue || []);
    const valueToSend = isMulti
      ? newValue?.map((option: Option) => option.value) || []
      : newValue?.value || null;
    onChange(valueToSend);
  };

  useEffect(() => {
    if (defaultValue) {

      const formattedValue = isMulti
        ? defaultValue.map((value: any) => {
            let label = value.nama;
            let valueOpt = value.id;

            console.log(value)

            if (value.negeri) {
              label = value.negeri;
              valueOpt = value.id;
            }

            if (value.keterangan) {
              label = value.keterangan;
              valueOpt = value.id;
            }

            if (value.daerah) {
              label = value.daerah;
              valueOpt = value.id;
            }

            if (value.negeri_name) {
              label = value.negeri_name;
              valueOpt = value.negeri_id;
            }

            if (value.daerah_name) {
              label = value.daerah_name;
              valueOpt = value.daerah_id;
            }

            if (value.pusat_tanggungjawap_name) {
              label = value.pusat_tanggungjawap_name;
              valueOpt = value.pusat_tanggungjawap_id;
            }
            return { value: valueOpt, label: label };
          })
        : defaultOptions.filter(
            (option: any) => option.value === defaultValue
          ) || [];

      setValue(formattedValue);
      onChange(
        isMulti
          ? formattedValue.map((option: Option) => option.value)
          : defaultValue
      );
    }
  }, [defaultOptions, defaultValue, isMulti]);

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel fontSize="sm" fontWeight="500" mb="8px">
        {label}
      </FormLabel>

      <CreatableSelect
        isMulti={isMulti}
        isClearable
        isDisabled={isLoading || readOnly}
        isLoading={isLoading}
        onChange={handleOnChange}
        onCreateOption={handleCreate}
        options={defaultOptions}
        value={value}
        styles={{
          control: (provided) => ({
            ...provided,
            background: "transparent",
            border: "1px solid #E0E5F2 !important",
            borderRadius: "0px",
            width: "100%",
            paddingLeft: "6px",
          }),
          placeholder: (provided) => {
            return {
              ...provided,
              color: "#A3AED0",
              fontSize: "14px",
            };
          },
          option: (provided) => ({
            ...provided,
            fontSize: "14px",
          }),
          singleValue: (provided) => ({
            ...provided,
            fontSize: "14px",
          }),
        }}
        required={required}
      />

      {isInvalid && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
