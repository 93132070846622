import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Text, List, Icon, Button } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdFileOpen } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { formatDate } from "utils/helpers";
import { useUsahawanIntegratorList } from "hooks/admin/kewangan/useUsahawanIntegratorList";

const columnHelper = createColumnHelper<any>();

const RingkasanLejar = () => {
  const { isLoading, error, usahawanIntegratorList } =
    useUsahawanIntegratorList();

  const columns = [
    columnHelper.accessor("usahawan_integrator", {
      header: () => <Text variant="tableHeading">Nama</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kp_ssm", {
      header: () => <Text variant="tableHeading">No. KP/ SSM</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <NavLink
          to={{
            pathname: `/admin/kewangan/ringkasan-lejar/janaan`,
            state: {
              nama: info.row.original.usahawan_integrator,
              no_kp_ssm: info.row.original.no_kp_ssm,
              id: info.row.original.user_id_integrator_id,
              user_type: info.row.original.role,
              type: "ringkasan-lejar",
            },
          }}
        >
          <Button
            colorScheme="green"
            variant="outline"
            fontSize="sm"
            leftIcon={
              <Icon w="20px" h="20px" color={"green.500"} as={MdFileOpen} />
            }
          >
            Jana Ringkasan Lejar
          </Button>
        </NavLink>
      ),
    }),
  ];

  if (isLoading) return <FullScreenLoader />;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={usahawanIntegratorList}
        title={"Senarai Usahawan Dan Integrator"}
        resourceSortingChildren={<Sorting />}
        allowPagination
      />
    </Box>
  );
};

export default RingkasanLejar;
