import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getDokumenURL } from "services/admin/kewangan/kewanganService";

export function useDokumenURL(type: string | null, params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let currentSearchParams: Record<string, string> = {};

  for (const [key, value] of searchParams) {
    currentSearchParams[key] = value;
  }

  const {
    isLoading,
    data: dokumenURL,
    error,
  } = useQuery({
    queryKey: ["dokumenURL", type, currentSearchParams],
    queryFn: () =>
      getDokumenURL(type, {
        ...params,
        ...currentSearchParams,
      }),
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
  });

  return { isLoading, error, dokumenURL };
}
